/*=======  Offers  =======*/
.offers-area {
  .offer-boxes-loop {
    .offer-box {
      margin-bottom: 60px;
      .offer-desc {
        background-color: $white;
        padding: 65px 60px 60px;
        margin-left: 60px;
        margin-right: 60px;
        border-top: 6px solid $primary-color;
        margin-top: -75px;
        z-index: 2;
        position: relative;
        @media #{$md} {
          margin-left: 30px;
          margin-right: 30px;
          padding: 65px 40px 60px;
        }
        @media #{$lsm} {
          margin-left: 20px;
          margin-right: 20px;
          padding: 40px 30px 30px;
          margin-top: -50px;
        }
        @media #{$xsm} {
          margin-left: 15px;
          margin-right: 15px;
          padding: 40px 20px 30px;
          margin-top: -30px;
        }
        @media #{$tiny} {
          margin-left: 5px;
          margin-right: 5px;
          padding: 30px 15px 20px;
        }
        .main-btn {
          box-shadow: none;
          margin-top: 30px;
          @media #{$xsm} {
            padding: 0 35px;
            line-height: 40px;
          }
        }

        .title-wrap {
          border-bottom: 2px solid #ececec;
          margin-bottom: 40px;
          padding-bottom: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title {
            .room-cat {
              text-transform: uppercase;
              padding-bottom: 10px;
              font-size: 14px;
              font-weight: 700;
              letter-spacing: 3px;
              @media #{$xsm} {
                letter-spacing: 2px;
              }
            }
            h2 {
              font-size: 48px;
              letter-spacing: -1px;
              @media #{$lsm} {
                font-size: 32px;
              }
              @media #{$xsm} {
                font-size: 24px;
              }
            }
          }

          .price {
            font-size: 40px;
            color: $primary-color;
            font-weight: 700;
            text-align: right;
            line-height: 1;
            @media #{$md} {
              font-size: 32px;
            }
            @media #{$lsm} {
              font-size: 28px;
            }
            @media #{$xsm} {
              font-size: 20px;
            }
            span {
              font-size: 16px;
            }
          }
        }

        .offer-text,
        .offer-feature {
          font-family: $roboto;
        }

        .offer-feature {
          @media #{$md} {
            margin-top: 50px;
          }
          @media #{$sm} {
            margin-top: 50px;
          }
          li {
            width: 50%;
            float: left;
            margin-bottom: 20px;
            padding-left: 40px;
            position: relative;
            i {
              color: $primary-color;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
}

/*=======  Gallery  =======*/
.gallery-loop {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;

  @media #{$sm} {
    grid-gap: 10px;
  }

  @media #{$xsm} {
    grid-gap: 10px;
  }

  .single-gallery-image {
    overflow: hidden;
    img {
      @include transition(0.4s);
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  .single-gallery-image:nth-child(3),
  .single-gallery-image:nth-child(6),
  .single-gallery-image:nth-child(9),
  .single-gallery-image:nth-child(12) {
    grid-column: 1 / 3;
  }

  .single-gallery-image:nth-child(3) {
    grid-row: 2 / 3;
  }

  .single-gallery-image:nth-child(6) {
    grid-row: 4 / 5;
  }

  .single-gallery-image:nth-child(9) {
    grid-row: 6 / 7;
  }

  .single-gallery-image:nth-child(12) {
    grid-row: 8 / 9;
  }
}
