/*=======  Restaurant CSS  =======*/
.restaurant-tab-area {
  .restaurant-rood-list {
    .nav-link {
      padding: 0;
      border-radius: 0;
      @include transition(0.3s);
      background-color: $white;
      text-align: center;
      display: block;
      padding: 35px 25px;
      margin-bottom: 30px;
      @media #{$xsm} {
        padding: 25px;
      }
      .title {
        display: block;
        font-size: 12px;
        font-weight: 700;
        padding-top: 20px;
        text-transform: uppercase;
        @include transition(0.3s);
        color: $heading-color;
      }
      i {
        color: $primary-color;
        font-size: 60px;
        @include transition(0.3s);
        @media #{$xsm} {
          font-size: 40px;
        }
      }
      &.active,
      &:hover {
        background-color: $primary-color;
        i,
        .title {
          color: $white;
        }
      }
    }
  }
  .food-box {
    margin-bottom: 30px;
    @media #{$tiny} {
      margin-left: -5px;
      margin-right: -5px;
    }
    .thumb {
      position: relative;
      img {
        width: 100%;
      }
      .price {
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: $primary-color;
        font-size: 18px;
        font-weight: 700;
        color: $white;
        padding: 8px 15px;
      }
    }
    .desc {
      padding: 30px;
      background-color: $white;
      position: relative;
      @media #{$xsm} {
        padding: 15px;
      }
      a.cat {
        font-size: 12px;
        color: $text-color;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 10px;
        @media #{$xsm} {
          letter-spacing: 1px;
        }
        @media #{$tiny} {
          letter-spacing: 0;
        }
      }
      h4 {
        font-size: 24px;
        @media #{$xsm} {
          font-size: 20px;
        }
        @media #{$tiny} {
          font-size: 18px;
        }
      }
      .link {
        color: #d2d2d2;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
        @include transition(0.3s);
        visibility: hidden;
        opacity: 0;
      }
    }
    &:hover {
      .desc .link {
        visibility: visible;
        opacity: 1;
        right: 30px;
        @media #{$xsm} {
          right: 10px;
        }
      }
    }
  }
}
.menu-area.gradient-white {
  background: linear-gradient(
    45deg,
    rgb(248, 248, 248) 0%,
    rgb(255, 255, 255) 100%
  );
}

.feature-room-section.with-bg {
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: $heading-color;
    opacity: 0.6;
    z-index: -1;
  }
}
